import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '@/plugins/store'

type SeverityLevel = 'success' | 'info' | 'warning' | 'error'

interface NoticePayload {
  message: string
  expires?: number
  level?: SeverityLevel
  // if a type is specified it won't be accepted if there's already a notice
  // displaying with the same type
  type?: string
}

export interface Notice {
  id: string
  message: string
  expires: number
  level: SeverityLevel
  type?: string
}

@Module({ namespaced: true, name: 'notices', dynamic: true, store })
export default class NoticesModule extends VuexModule {
  notices: Notice[] = []

  get lastFive () {
    return this.notices.slice(0, 5)
  }

  @Mutation
  addNotice ({ message, level = 'info', expires = 10, type }: NoticePayload) {
    const id = btoa(`${(new Date()).getTime() + Math.round(Math.random() * 1000)}`)
    if (type && this.notices.find(n => n.type === type)) return
    this.notices.push({
      message,
      expires: expires + Math.round((new Date()).getTime() / 1000),
      level,
      id,
      type
    })
  }

  @Mutation
  removeNotice (id: string) {
    console.log(id)

    const idx: number = this.notices.findIndex((el: Notice): boolean => el.id === id)
    if (idx > -1) {
      this.notices.splice(idx, 1)
    }
  }
}
