export const B2C_APPLICATION_ID: string = '0f18f40e-3e47-4a87-ae5a-c0fa94d36762'
export const B2C_TENANT: string = 'ijruaccount'
export const B2C_SIGNUPIN_FLOW: string = 'b2c_1_signupin_v2'
export const B2C_SCOPE: string = ['openid', 'offline_access', B2C_APPLICATION_ID].join('+')

export const B2C_AUTHORIZE_URL: string = `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_SIGNUPIN_FLOW}/oauth2/v2.0/authorize`
export const B2C_TOKEN_URL: string = `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_SIGNUPIN_FLOW}/oauth2/v2.0/token`
export const B2C_LOGOUT_URL: string = `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_SIGNUPIN_FLOW}/oauth2/v2.0/logout`

export const FUNCTIONS_URL: string = process.env.VUE_APP_FUNCTIONS_URL ?? ''

export const APPINSIGHTS_INSTRUMENTATIONKEY: string = process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY ?? ''
export const GOOGLE_ANALYTICS_TRACKING_CODE: string = 'UA-129003433-3'
export const SENTRY_DSN: string = 'https://0db00ab4477447a6899a58ea0e023970@sentry.io/1814897'
export const SENTRY_ENVIRONMENT: string = process.env.SENTRY_ENVIRONMENT ?? 'development'
export const SENTRY_VERSION: string | undefined = process.env.SENTRY_VERSION
