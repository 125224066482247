import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signedout',
      name: 'signedout',
      // route level code-splitting
      // this generates a separate chunk (signedout.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: async () => await import(/* webpackChunkName: "signedout" */ './views/SignedOut.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: async () => await import(/* webpackChunkName: "signup" */ './views/Signup.vue')
    },
    {
      path: '/clubs',
      name: 'clubs',
      component: async () => await import(/* webpackChunkName: "clubs" */ './views/Clubs.vue')
    },
    {
      path: '/clubs/:clubId/roster',
      name: 'club-roster',
      component: async () => await import(/* webpackChunkName: "club-roster" */ './views/ClubRoster.vue')
    },
    {
      path: '/events',
      name: 'events',
      component: async () => await import(/* webpackChunkName: "events" */ './views/Events.vue')
    },
    {
      path: '/events/:eventId/delegations',
      name: 'event-delegations',
      component: async () => await import(/* webpackChunkName: "event-delegations" */ './views/EventDelegations.vue')
    },
    {
      path: '/events/:eventId/delegations/:delegationId/roster',
      name: 'delegation-roster',
      component: async () => await import(/* webpackChunkName: "delegation-roster" */ './views/DelegationRoster.vue')
    },
    {
      path: '/events/:eventId/delegations/:delegationId/entries',
      name: 'delegation-entries',
      component: async () => await import(/* webpackChunkName: "delegation-entries" */ './views/CompetitionEntries.vue')
    },
    {
      path: '/events/:eventId/delegations/:delegationId/all-entries',
      name: 'delegation-all-entries',
      component: async () => await import(/* webpackChunkName: "delegation-all-entries" */ './views/DelegationEntriesSummary.vue')
    },
    {
      path: '/events/:eventId/delegations/:delegationId/forms',
      name: 'delegation-forms',
      component: async () => await import(/* webpackChunkName: "delegation-forms" */ './views/DelegationForms.vue')
    },
    {
      path: '/events/:eventId/delegations/:delegationId/volunteers',
      name: 'delegation-volunteer-nominations',
      component: async () => await import(/* webpackChunkName: "delegation-volunteer-nominations" */ './views/DelegationVolunteerNominations.vue')
    },
    {
      path: '/events/:eventId/admin/export',
      name: 'event-export',
      component: async () => await import(/* webpackChunkName: "event-admin" */ './views/EventExport.vue')
    },
    {
      path: '/events/:eventId/admin/attendance',
      name: 'event-attendance',
      component: async () => await import(/* webpackChunkName: "event-attendance" */ './views/EventAttendance.vue')
    },
    {
      path: '/events/:eventId/admin/forms',
      name: 'event-forms',
      component: async () => await import(/* webpackChunkName: "event-forms" */ './views/EventForms.vue')
    },
    {
      path: '/events/:eventId/admin/config',
      name: 'event-config-base',
      component: async () => await import(/* webpackChunkName: "event-config-base" */ './views/EventBaseConfig.vue')
    },
    {
      path: '/events/:eventId/admin/competitions',
      name: 'event-config-competitions',
      component: async () => await import(/* webpackChunkName: "event-config-competitions" */ './views/EventCompetitionConfig.vue')
    },
    {
      path: '/events/:eventId/admin/entries',
      name: 'event-entries',
      component: async () => await import(/* webpackChunkName: "event-entries" */ './views/EventEntries.vue')
    },
    {
      path: '/events/:eventId/admin/volunteers',
      name: 'event-volunteer-nominations',
      component: async () => await import(/* webpackChunkName: "event-volunteer-nominations" */ './views/EventVolunteerNominations.vue')
    }
  ]
})
