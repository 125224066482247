import 'babel-polyfill'
import 'unfetch/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import { createProvider } from './plugins/vue-apollo'
import './registerServiceWorker'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations as TracingIntegration } from '@sentry/tracing'
import gtagjs from 'vue-gtagjs'
import VueAppInsights from 'vue-application-insights'
import { APPINSIGHTS_INSTRUMENTATIONKEY, GOOGLE_ANALYTICS_TRACKING_CODE, SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_VERSION } from './config'
import { version } from '../package.json'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `registration-frontend@${SENTRY_VERSION ?? version}`,
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      new VueIntegration({ Vue, attachProps: true }),
      new TracingIntegration.BrowserTracing({
        tracingOrigins: ['registration.ijru.sport', 'registration.ijru.dev']
      })
    ],
    tracesSampleRate: 1.0
  })

  gtagjs(router, GOOGLE_ANALYTICS_TRACKING_CODE, { debug: true, scriptId: 'gtagjs' })

  Vue.use(VueAppInsights, {
    id: APPINSIGHTS_INSTRUMENTATIONKEY,
    router
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
