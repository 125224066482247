var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.signup === undefined || _vm.signup === false)?_c('ApolloQuery',{attrs:{"query":_vm.query,"variables":_vm.variables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [_c('v-card',[(isLoading)?_c('v-card-title',{staticClass:"loading apollo"},[_vm._v(_vm._s(_vm.$t('$apollo.loading')))]):(error)?_c('v-card-title',{staticClass:"error apollo"},[_vm._v(_vm._s(_vm.$t('$apollo.error')))]):(data.person)?[_c('v-row',{attrs:{"justify":"start"}},[(_vm.signup === undefined || _vm.signup === false)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"mx-auto mx-sm-3 d-flex justify-center align-center",attrs:{"flat":"","color":"grey lighten-3","height":"200px","width":"150px"}},[(data.person.photo)?_c('v-img',{attrs:{"src":data.person.photo,"type":"image/jpeg","contain":"","alt":"Photo","height":"100%","width":"100%"}}):_c('v-icon',{staticClass:"mx-auto",attrs:{"large":""}},[_vm._v("mdi-image")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":_vm.signup === undefined || _vm.signup === false ? 9 : 12}},[(_vm.personId)?_c('v-card-title',[_vm._v(_vm._s(data.person.fullName))]):_c('v-card-title',[_vm._v(_vm._s(_vm.$t('peopleInfo.welcome', { name: data.person.fullName })))]),(_vm.signup !== undefined && _vm.signup !== false)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('peopleInfo.signupText')))]):_vm._e(),(_vm.signup === undefined || _vm.signup === false)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('peopleInfo.memberId', { personId: data.person.personId })))]):_vm._e(),(_vm.signup === undefined || _vm.signup === false)?_c('v-card-text',[_vm._v(_vm._s(data.person.email))]):_vm._e()],1)],1),(_vm.signup !== undefined && _vm.signup !== false)?_c('ApolloMutation',{attrs:{"mutation":_vm.AddPersonMutation,"variables":{ data: _vm.cleanPerson(data.person, _vm.updateFields) },"update":_vm.updateUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mutate = ref.mutate;
var loading = ref.loading;
var error = ref.error;
return [_c('v-card-text',[_c('Form',{attrs:{"fields":_vm.updateFields},on:{"valid":function($event){_vm.valid = $event},"submit":function($event){return mutate()}},model:{value:(data.person),callback:function ($$v) {_vm.$set(data, "person", $$v)},expression:"data.person"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","loading":loading,"disabled":loading || !_vm.valid,"error":error},on:{"click":function($event){return mutate()}}},[_vm._v(_vm._s(_vm.$t('peopleInfo.signUp')))])],1)]}}],null,true)}):_vm._e()]:_c('v-card-title',{staticClass:"no-result apollo"},[_vm._v(_vm._s(_vm.$t('peopleInfo.noSuchPerson')))])],2)]}}],null,false,2193698135)}):_c('v-card',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":12}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('peopleInfo.welcome')))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('peopleInfo.signupText')))])],1)],1),_c('ApolloMutation',{attrs:{"mutation":_vm.AddPersonMutation,"variables":{ data: _vm.cleanPerson(_vm.signupPerson, _vm.updateFields) },"update":_vm.updateUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mutate = ref.mutate;
var loading = ref.loading;
var error = ref.error;
return [_c('v-card-text',[_c('Form',{attrs:{"fields":_vm.updateFields},on:{"valid":function($event){_vm.valid = $event},"submit":function($event){return mutate()}},model:{value:(_vm.signupPerson),callback:function ($$v) {_vm.signupPerson=$$v},expression:"signupPerson"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","loading":loading,"disabled":loading || !_vm.valid,"error":error},on:{"click":function($event){return mutate()}}},[_vm._v(_vm._s(_vm.$t('peopleInfo.signUp')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }