import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '@/plugins/store'

import VuexPersistance from 'vuex-persist'

const VuexLocal = new VuexPersistance({
  storage: window.localStorage,
  modules: ['auth'],
  key: 'registration-auth'
})

interface AccessToken {
  token: string
  expires: number
}

@Module({ namespaced: true, name: 'auth', dynamic: true, store })
export default class AuthModule extends VuexModule {
  accessToken: AccessToken = {
    token: '',
    expires: 0
  }

  @Mutation
  setAccessToken ({ accessToken, expires }: { accessToken: string, expires: number }) {
    this.accessToken.token = accessToken
    this.accessToken.expires = expires
  }
}

VuexLocal.plugin(store)
