import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Settings as LuxonSettings } from 'luxon'
import en from '@/i18n/en.json'
import { getModule } from 'vuex-module-decorators'
import SettingsModule from '../store/settings'

Vue.use(VueI18n)

const settings = getModule(SettingsModule)

const loadedLocales = ['en']

const i18n = new VueI18n({
  locale: settings.lang,
  fallbackLocale: 'en',
  messages: {
    en
  }
})

function setLocale (lang: string): string {
  i18n.locale = lang
  settings.setLocale(lang)
  LuxonSettings.defaultLocale = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export async function loadLocale (lang: string): Promise<string> {
  if (loadedLocales.includes(lang)) {
    return setLocale(lang)
  }

  const locale = await import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.json`)

  i18n.setLocaleMessage(lang, locale)
  loadedLocales.push(lang)
  return setLocale(lang)
}

setLocale(settings.lang)

export default i18n
