import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '@/plugins/store'

import VuexPersistance from 'vuex-persist'

export type ThemeName = 'light' | 'dark' | 'auto'

const VuexLocal = new VuexPersistance({
  storage: window.localStorage,
  modules: ['settings'],
  key: 'registration-settings'
})

@Module({ namespaced: true, name: 'settings', dynamic: true, store })
export default class SettingsModule extends VuexModule {
  lang: string = 'en'
  theme: ThemeName = 'auto'

  @Mutation
  setLocale (lang: string) {
    this.lang = lang
  }

  @Mutation
  setTheme (theme: ThemeName) {
    this.theme = theme
  }

  get darkTheme () {
    console.log(this.theme)
    if (this.theme === 'light') return false
    if (this.theme === 'dark') return true
    return window?.matchMedia('(prefers-color-scheme: dark)').matches
  }
}

VuexLocal.plugin(store)
