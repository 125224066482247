import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from './vue-i18n'
import { getModule } from 'vuex-module-decorators'
import SettingsModule from '../store/settings'

const settings = getModule(SettingsModule)

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    dark: settings.darkTheme
  },
  lang: {
    t: (key: string, ...params: string[]) => i18n.t(key, params)
  }
})
