import Vue from 'vue'
import VueApollo from 'vue-apollo'
// import ApolloClient, { InMemoryCache, defaultDataIdFromObject } from 'apollo-boost'
import { getModule } from 'vuex-module-decorators'
import { FUNCTIONS_URL } from '../config'
import AuthModule from '../store/auth'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import NoticesModule from '@/store/notices'

// Install the vue plugin
Vue.use(VueApollo)

// Http endpoint
const httpEndpoint = `${FUNCTIONS_URL}/graphql`

function customDataIdFromObject (result: { __typename?: string, [prop: string]: any }): string | null {
  const tn = result.__typename

  if (!tn || !tn.length) return null

  const tnFirst = tn.substring(0, 1).toLocaleLowerCase()
  const tnRest = tn.substring(1)

  let path = tnFirst + tnRest + 'Id'

  if (tn === 'VolunteerSlot') return null
  if (tn === 'CompetitionEntry') path = 'entryId'
  if (tn === 'GenderDefinition') path = 'genderId'
  if (tn === 'AgegroupDefinition') path = 'agegroupId'

  if (!result[path]) return null

  return `${tn}:${result[path] as string}`
}

// Call this in the Vue app file
export function createProvider () {
  const cache = new InMemoryCache({
    dataIdFromObject: (object: any): string => {
      return customDataIdFromObject(object) ?? defaultDataIdFromObject(object) ?? ''
    }
  })
  const notices = getModule(NoticesModule)

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    const state = (window as any).__APOLLO_STATE__
    if (state?.defaultClient) {
      // Restore state
      cache.restore(state.defaultClient)
    }
  }

  const httpLink = createHttpLink({
    uri: httpEndpoint,
    credentials: 'omit'
  })

  const authLink = setContext((_, { headers }) => {
    const auth = getModule(AuthModule)
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        origin: window.location.origin,
        authorization: auth.accessToken.token ? `Bearer ${auth.accessToken.token} ` : ''
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors != null) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations as any as string}, Path: ${path as any as string} `
        )
        notices.addNotice({ message: message, level: extensions?.code === 'FORCE_REQUIRED' ? 'warning' : 'error' })
      })
    }
    if (networkError != null) {
      console.error(`[Network error]: ${networkError as any as string} `)
      notices.addNotice({ message: networkError.message, level: 'error' })
    }
  })

  // Create apollo client
  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authLink,
      httpLink
    ]),
    cache
  })

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
    // defaultOptions: {
    //   $query: {
    //     // fetchPolicy: 'cache-and-network',
    //   }
    // },
    // errorHandler (error) {
    // eslint-disable-next-line no-console
    // console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    // }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient: ApolloClient<InMemoryCache>, token: string, expiresIn: number = 3600) {
  // try {
  //   // await apolloClient.queryManager.fetchQueryRejectFns
  //   await apolloClient.resetStore()
  // } catch (e) {
  //   // eslint-disable-next-line no-console
  //   console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  // }
  if (token) {
    const auth = getModule(AuthModule)
    auth.setAccessToken({ accessToken: token, expires: (new Date()).getTime() + (expiresIn * 1000) })
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient: ApolloClient<InMemoryCache>) {
  try {
    await apolloClient.cache.reset()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', (err as Error).message)
  }
  const auth = getModule(AuthModule)
  if (typeof localStorage !== 'undefined') {
    auth.setAccessToken({ accessToken: '', expires: -1 })
  }
}
